/* eslint-disable complexity */
import React, { FC, useEffect, useCallback, useRef, useState, useMemo } from 'react'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { set } from 'dot-prop'
import Decimal from 'decimal.js'
import { detect } from 'detect-browser'

import { Form, FormField, Grid, GridRow, Label, Modal } from 'semantic-ui-react'
import { SimpleBox } from '../../components/SimpleBox'
import { SimpleCard } from '../../components/SimpleCard'
import { SimpleForm } from '../../components/SimpleForm'
import { SimpleText } from '../../components/SimpleText'
import { observer } from '../../decorators'
import { Submit } from '../../fragments/payment/SubmitNew'
import { toMoney } from '../../shared/format'
import Router from '../../shared/router'
import { CHARGEBEE_PAYMENT_SOURCE_TYPES as PAYMENT_TYPES, ChargebeeItemPrice } from '../../shared/types'
import { Plan } from '../../type'
import { useNavigation } from '../../hooks/useNavigation'
import { DiscountWarning } from '../../fragments/modal/payment/DiscountWarning'
import { ChargebeeCoupon, CouponData } from '../../utils/coupon'
import { ActivateNow } from '../../fragments/ActivateNow'
import { PaymentData } from '../../fragments/payment/ICardProps'
import { CardComponent } from '../../fragments/payment/CardComponent'
import { useSiteSetting } from '../../hooks/useSiteSetting'
import {
  trackBeginCheckout,
  trackEmailValidationSuccess,
  trackPurchase,
} from '../../utils/tracking'
import { nameRegex, emailRegex } from '../../const'
import PaymentMethod from '../../components/Checkout/PaymentMethod'
import AddonCard from '../../components/Checkout/AddonCard'
import SummaryCard from '../../components/Checkout/SummaryCard'
import Tags from '../../components/Checkout/Tags'
import {
  applyCoupon,
  createCheckoutSubscription,
  queryPlan,
  queryUpsellItems,
  parsePayPalReturnData,
  calculateCouponDiscount,
  resolveFreeMonths,
} from '../../graphql/checkout'
import { isEmailValid } from '../../utils'
import Cookies from 'universal-cookie'
import {
  Feature,
  CreateCheckoutSubscriptionResult,
  queryCheckoutUpsellsResult,
} from '../../types/checkout'
import Session from '../../shared/storages/session'

const EXTRA_USER_ADDON = 'k5i47h4q'
const PLAN_PERIOD_MAP = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2,
  YEAR: 3,
}

const withApplePay = ['edge-ios', 'ios', 'safari', 'ios-webview'].indexOf(detect()?.name || 'undefined') >= 0

const cookies = new Cookies()

const PaymentPayPalIcon = require('~assets/images/icons/payment-paypal.svg')
const PaymentCardsImage = require('~assets/images/payment-cards.svg')
const PaymentGoogleImage = require('~assets/images/payment-google.svg')
const PaymentAppleImage = require('~assets/images/payment-apple.svg')
const ArrowUp = require('~assets/images/icons/icon-arrow-up-red.svg')
const ArrowDown = require('~assets/images/icons/icon-arrow-down-red.svg')

export type Price = Pick<ChargebeeItemPrice, 'id' | 'name' | 'price' | 'trialPeriod'>
export type CardDetails = { number: string, expiry: string, cvv: string, token?: string }
type CouponWarningProps = { coupon: ChargebeeCoupon, plan: Plan }

const ExternalLink: FC<{ path: string, text: string }> = ({ path, text }) => (
  <a href={`https://virtualshield.com/${path}`} target="_blank" rel="noreferrer">{text}</a>
)

type Props = { trialEnabled: boolean, promotion: boolean }
const CheckoutProduct: FC<Props> & { authorize: boolean; auth: string } = props => {
  // States & Variables
  const forceAuthorizeOne = useSiteSetting('FORCE_AUTHORIZE_ON_ONE_PLANS')
  const price = Router.params.price as string
  const priceIds = price.split(':') || []
  const paypalData = parsePayPalReturnData()

  const { setNav, resetNav } = useNavigation()

  const cardRef = useRef<any>()
  const captchaRef = useRef<any>()
  const fieldsetRef = useRef<HTMLFieldSetElement>(null)
  const loadingState = useState<boolean>(!!paypalData.result)
  const [firstPlanId, setFirstPlanId] = useState<string>(priceIds[0])
  const [activateNow, setActivateNow] = useState<boolean>(paypalData.activateNow)
  const [coupon, setCoupon] = useState<ChargebeeCoupon | undefined>(undefined)
  const [couponWarningPayload] = useState<CouponWarningProps>()
  const [modal, setModal] = useState<'discount-warning' | undefined>(undefined)
  const [cardErrors, setCardErrors] = useState<string[]>([])
  const [paymentMethod, setPaymentMethod] = useState<PAYMENT_TYPES | undefined>(Router.qs.paypal ? 'PAYPAL' : undefined)
  const [addons, setAddons] = useState<Array<string>>(paypalData.addons || [])
  const [emailError, setEmailError] = useState<boolean>(false)
  const [data, setData] = useState<PaymentData>({
    email: (paypalData.email || (Router.qs.email as string) || '').trim().replace(/ /g, '+'),
    firstName: '',
    lastName: '',
    zip: '',
    card: { number: '', expiry: '', cvv: '' },
    paypal: {},
    captcha: false,
  })
  const [mobileOrderSummaryTabOpen, setMobileOrderSummaryTabOpen] = useState<boolean>(false)

  // GraphQL
  const { data: planData, loading: loadingPlan } =
    useQuery<{ plan: Plan }>(queryPlan, { variables: { id: firstPlanId } })
  const [fetchUpsellItems, { data: upsellItems, loading: loadingUpsellItems }] =
    useLazyQuery<queryCheckoutUpsellsResult>(queryUpsellItems)
  const [startCheckout, { loading: loadingCheckoutConfig, data: checkoutSubscriptionData }] =
    useMutation<CreateCheckoutSubscriptionResult>(createCheckoutSubscription)
  const [getCouponData, { loading: couponLoading }] =
    useMutation<CouponData>(applyCoupon)

  // Shortcuts
  const plan = planData?.plan
  const isFamily = plan?.name.toLowerCase().includes('family')
  const months = (plan?.period || 0) * (plan?.periodUnit === 'YEAR' ? 12 : 1)
  const frequency = plan && { period: plan.period, periodUnit: PLAN_PERIOD_MAP[plan.periodUnit] }
  const isOne = plan?.item?.metadata?.product === 'one'

  const checkoutConfig = checkoutSubscriptionData?.createCheckoutSubscription?.checkoutConfiguration
  const cartExtraFeatures = (checkoutConfig?.cartExtraFeatures || []).reduce((acc, feature) => {
    const addon = feature.chargebeeId !== EXTRA_USER_ADDON &&
      upsellItems?.addons.find(addon => addon.itemId === feature.chargebeeId)

    return addon ? [...acc, { ...feature, price: parseFloat(addon.price), addonId: addon.id }] : acc
  }, [])

  const extraUserAddon = checkoutConfig?.cartExtraFeatures?.find(feature => feature.chargebeeId === EXTRA_USER_ADDON) &&
    upsellItems?.addons.find(addon => addon.itemId === EXTRA_USER_ADDON)

  const addAnotherUser = extraUserAddon && addons.includes(extraUserAddon.itemId)
  const selectedFeatures: Feature[] = cartExtraFeatures.filter(feature => addons.includes(feature.chargebeeId))

  const addonsTotalPrice = selectedFeatures.reduce((acc, feature) => acc + feature.price, 0)
  const buttonDisabled = paymentMethod === 'CARD' && cardErrors.length > 0
  const trialEnabled = props.trialEnabled && checkoutConfig?.payNowEnabled && Router.qs.noTrial === undefined
  const priceWithAddon = new Decimal(plan?.price || '0').add(new Decimal(addonsTotalPrice))
  const cartSteps = (checkoutConfig?.cartSteps || []).filter(step => (
    step.stepType !== 'extra_features' || cartExtraFeatures.length > 0
  ))

  const priceTotal = useMemo(() => (
    priceWithAddon.minus(calculateCouponDiscount(priceWithAddon, coupon))
  ), [priceWithAddon, coupon])

  // Effects
  useEffect(() => {
    const checkoutDataCache = JSON.parse(localStorage.getItem('vs_checkout_cache') ?? '{}')
    if (!loadingState[0] && Object.keys(checkoutDataCache).length > 0) {
      setData(checkoutDataCache.data)
      setPaymentMethod(checkoutDataCache.type)
      checkoutDataCache.coupon && setCoupon(checkoutDataCache.coupon)
      // checkoutDataCache.addons?.length && setAddons(checkoutDataCache.addons)
      checkoutDataCache.activateNow !== undefined && setActivateNow(checkoutDataCache.activateNow)
    }

    const variables = { affiliateId: Session.affiliate.affiliateId || null, planId: firstPlanId }
    startCheckout({ variables }).then(({ data }) => {
      const config = data?.createCheckoutSubscription?.checkoutConfiguration
      const variables: Hash = { priceId: firstPlanId, currency: Session.currency }
      variables.code = paypalData.coupon || Router.qs.coupon || checkoutDataCache?.coupon?.name || config?.defaultCoupon
      variables.code && getCouponData({ variables }).then(({ data }) => {
        if (data?.coupon) {
          setCoupon(data.coupon)
          plan && trackBeginCheckout(plan, isFamily, coupon, 'coupon')
        }
      })

      if (!checkoutDataCache.type) {
        const method = config?.cartPaymentMethods[0]?.paymentMethod
        method && setPaymentMethod(method === 'credit_card' ? 'CARD' : method.toUpperCase() as PAYMENT_TYPES)
      }

      const urlAddons = (Router.qs.addons as string).toString().split(',')
      if (!addons.length && urlAddons.length && config?.cartExtraFeatures?.length) {
        setAddons(config.cartExtraFeatures.reduce((acc, feature) => {
          urlAddons.includes(feature.chargebeeId) && acc.push(feature.chargebeeId)
          return acc
        }, [] as string[]))
      }
    })

    setNav('no-sign-in')
    return resetNav
  }, [])

  useEffect(() => {
    localStorage.setItem('vs_checkout_cache', JSON.stringify({
      id: firstPlanId, addons, coupon, activateNow, data, type: paymentMethod,
    }))
  }, [firstPlanId, addons, coupon, activateNow, data, paymentMethod])

  useEffect(() => {
    if (plan) {
      trackBeginCheckout(plan, isFamily, coupon, 'plan')
      plan.currencyCode && (Session.currency = plan.currencyCode)
      fetchUpsellItems({ variables: { ...frequency, currency: plan.currencyCode } })
    }
  }, [plan])

  useEffect(() => {
    fieldsetRef.current && paypalData.result && fieldsetRef.current.querySelector('.submit-box')?.scrollIntoView()
  }, [fieldsetRef.current])

  useEffect(() => { !loadingState[0] && setActivateNow(!trialEnabled) }, [trialEnabled])

  const freeMonths = useMemo(() => resolveFreeMonths(props.promotion, checkoutConfig?.freeMonths), [checkoutConfig])
  const defaultPromo = useMemo(() => (
    activateNow && props.promotion && (typeof checkoutConfig?.freeMonths) !== 'number'
  ), [activateNow, checkoutConfig])

  // Callbacks
  const handleChangePaymentMethod = useCallback((type: PAYMENT_TYPES) => {
    setPaymentMethod(type === paymentMethod ? undefined : type)
  }, [paymentMethod])

  const handleDataChange = useCallback(({ name, value }: { name: string; value: any }) => {
    if ((name !== 'firstName' && name !== 'lastName') || (value === '' || nameRegex.test(value))) {
      setData(d => ({ ...set(d, name, value) }))
    }
  }, [])

  const handleEmailChange = useCallback((value: string) => {
    value = value.toLocaleLowerCase()
    emailRegex.test(value) && trackEmailValidationSuccess()
    setData(d => ({ ...set(d, 'email', value) }))
  }, [])

  const handleCouponChange = useCallback((coupon?: ChargebeeCoupon) => {
    setCoupon(coupon)

    if (coupon) {
      cookies.set('coupon', coupon.name, { path: '/' })
    } else {
      cookies.remove('coupon', { path: '/' })
    }

    const action = coupon ? 'add coupon' : 'remove coupon'
    plan && trackBeginCheckout(plan, isFamily, coupon, action)
  }, [plan])

  const handleSelectAddon = useCallback((addonId: string) => {
    if (addons.includes(addonId)) {
      setAddons(addons.filter(id => id !== addonId))
    } else {
      setAddons(addons.concat(addonId))
    }
  }, [addons])

  const handleToggleAddUser = useCallback(() => {
    !loadingState[0] && extraUserAddon && handleSelectAddon(extraUserAddon.itemId)
  }, [addons, extraUserAddon])

  const applyWarnedCoupon = useCallback(() => {
    if (couponWarningPayload) {
      const path = location.pathname.split('/')[1]
      Router.updateHistory(`/${path}/${couponWarningPayload.plan.id}`)
      setFirstPlanId(couponWarningPayload.plan.id)
      setCoupon(undefined)
      setModal(undefined)
    }
  }, [couponWarningPayload])

  // Early return
  if (loadingPlan || loadingUpsellItems || loadingCheckoutConfig || couponLoading) {
    return <div>loading</div>
  } else if (!plan) {
    Router.redirect('/pricing')
    return <></>
  }

  // Markups
  const handleEmailInputBlur = () => {
    data.email.length > 0 && !isEmailValid(data.email) && setEmailError(true)
  }

  const submitMarkup = () => (
    <>
      {trialEnabled && (
        <ActivateNow
          months={months}
          activateNow={activateNow}
          setActivateNow={setActivateNow}
          bienniallyFreeMonths={freeMonths}
        />
      )}
      <SimpleBox mt={3} className="submit-box">
        <Submit
          type={paymentMethod}
          data={data}
          coupon={coupon}
          cardRef={cardRef}
          planDetail={frequency}
          captchaRef={captchaRef}
          priceIds={[firstPlanId, ...addons]}
          promotion={defaultPromo}
          disabled={buttonDisabled}
          activateNow={activateNow}
          loadingState={loadingState}
          price={priceTotal.toNumber()}
          checkoutConfigId={checkoutConfig?.id}
          subscriptionId={checkoutSubscriptionData?.createCheckoutSubscription?.id}
          addonIds={selectedFeatures.map(feature => feature.addonId)}
          paypalReturnPath={`/${location.pathname.split('/')[1]}`}
          purchaseFinished={() => trackPurchase(plan, isFamily, activateNow, coupon)}
        />
      </SimpleBox>
      <SimpleBox mt={3}>
        <div className="text--x-small">
          By clicking Complete Purchase, you agree to our <ExternalLink path="legal/terms/" text="Terms of Service" />
          {' '}and acknowledge our <ExternalLink path="legal/privacy/" text="Privacy Policy" />. To ensure uninterrupted
          access, your subscription will automatically renew at the end of each billing cycle using the payment method
          provided until you cancel it by disabling auto-renewal, which can be done at any time.
        </div>
      </SimpleBox>
    </>
  )

  const steps: any = {
    'email': {
      title: (
        <SimpleText size="title3">
          Enter Your Email Address
          <span className="enter-email" />
        </SimpleText>
      ),
      body: (
        <SimpleBox mt={3}>
          <SimpleCard fluid padding="heavy">
            <FormField>
              <label htmlFor="email">Enter email address</label>
              <Grid style={{ margin: 0 }}>
                <GridRow verticalAlign="middle" className="email-input-row">
                  <SimpleForm.Input
                    type="email"
                    value={data?.email}
                    onChange={(d: any) => handleEmailChange(d.value)}
                    size="large"
                    required
                    fluid
                    showValidation={emailError}
                    handleInputBlur={handleEmailInputBlur}
                  />
                </GridRow>
                <GridRow verticalAlign="middle">
                  <div className="text--x-small">
                    We do not share your information and will contact you only
                    as needed to provide our service.
                  </div>
                </GridRow>
              </Grid>
            </FormField>
          </SimpleCard>
        </SimpleBox>
      ),
    },
    'extra_features': {
      title: (
        <>
          <SimpleText size="title3">Add Extra Protection</SimpleText>
          <Label style={{ background: '#E8EAF0' }}>
            <SimpleText color="grey" size="textSmall">
              Optional
            </SimpleText>
          </Label>
        </>
      ),
      body: (
        <SimpleBox className="addon-card-row">
          <SimpleBox
            mt={3}
            display="flex"
            flexDirection="row"
            className="addon-row"
          >
            {cartExtraFeatures.slice(0, 3).map(feature => (
              <AddonCard
                key={feature.id}
                title={feature.title}
                description={feature.description}
                icon={feature.imageUrl}
                price={feature.price}
                handleClickOnAdd={event => {
                  event.preventDefault()
                  handleSelectAddon(feature.chargebeeId)
                  event.currentTarget.blur()
                }}
                isSelected={addons.includes(feature.chargebeeId)}
                coupon={coupon}
                planPeriod={plan.period}
                freeMonths={activateNow ? freeMonths : 0}
              />
            ))}
          </SimpleBox>
        </SimpleBox>
      ),
    },
    'payment_methods': {
      title: (
        <SimpleText size="title3">
          Select Your Preferred Payment Method
        </SimpleText>
      ),
      body: {
        'credit_card': (
          <PaymentMethod
            title="Credit Card"
            icon={<img height={20} src={PaymentCardsImage} alt="cards" className="payment-icon" />}
            isCardOpen={paymentMethod === 'CARD'}
            handleToggleCard={() => handleChangePaymentMethod('CARD')}
          >
            <SimpleBox marginTop={25} paddingTop={18} borderTop="1px solid #E8EAF0">
              <CardComponent
                data={data}
                forwardRef={cardRef}
                captchaRef={captchaRef}
                onChange={handleDataChange}
                onErrors={setCardErrors}
                forceAuthorize={isOne && forceAuthorizeOne.enabled}
              />
              {submitMarkup()}
            </SimpleBox>
          </PaymentMethod>
        ),
        'paypal': (
          <PaymentMethod
            title="PayPal"
            icon={<img height={20} src={PaymentPayPalIcon} alt="paypal" className="payment-icon" />}
            isCardOpen={paymentMethod === 'PAYPAL'}
            handleToggleCard={() => handleChangePaymentMethod('PAYPAL')}
          >
            <SimpleBox marginTop={25} paddingTop={18} borderTop="1px solid #E8EAF0">
              {submitMarkup()}
            </SimpleBox>
          </PaymentMethod>
        ),
        'google_pay': (!withApplePay && (
          <PaymentMethod
            title="Google Pay"
            icon={
              <div className="payment-icon-box">
                <img height={12} src={PaymentGoogleImage} alt="google" />
              </div>
            }
            isCardOpen={paymentMethod === 'GOOGLE_PAY'}
            handleToggleCard={() => handleChangePaymentMethod('GOOGLE_PAY')}
          >
            <SimpleBox marginTop={25} paddingTop={18} borderTop="1px solid #E8EAF0">
              {submitMarkup()}
            </SimpleBox>
          </PaymentMethod>
        )),
        'apple_pay': (withApplePay && (
          <PaymentMethod
            title="Apple Pay"
            icon={
              <div className="payment-icon-box">
                <img height={10} src={PaymentAppleImage} alt="apple" />
              </div>
            }
            isCardOpen={paymentMethod === 'APPLE_PAY'}
            handleToggleCard={() => handleChangePaymentMethod('APPLE_PAY')}
          >
            <SimpleBox marginTop={25} paddingTop={18} borderTop="1px solid #E8EAF0">
              {submitMarkup()}
            </SimpleBox>
          </PaymentMethod>
        )),
      },
    },
  }

  const renderAllPaymentMethods = () => (checkoutConfig?.cartPaymentMethods?.map(entry => (
    <React.Fragment key={entry.id}>{steps['payment_methods'].body[entry.paymentMethod]}</React.Fragment>
  )))

  const renderSummaryCard = () => (
    <SummaryCard
      total={priceTotal.toNumber()}
      coupon={coupon}
      activateNow={activateNow}
      plan={plan}
      firstPlanId={firstPlanId}
      extraUserAddon={extraUserAddon}
      addAnotherUser={!!addAnotherUser}
      handleToggleAddUser={handleToggleAddUser}
      handleCouponChange={handleCouponChange}
      selectedFeatures={selectedFeatures}
      bienniallyFreeMonths={freeMonths}
      priceWithAddon={priceWithAddon.toNumber()}
      allowCoupon={checkoutConfig?.allowApplyCoupon}
    />
  )

  return (
    <fieldset disabled={loadingState[0]} style={{ margin: 0, padding: 0, border: 'none' }} ref={fieldsetRef}>
      {/* modal */}
      <Modal size="tiny" open={modal !== undefined} onClose={() => setModal(undefined)} closeIcon>
        <DiscountWarning
          isOpen={modal === 'discount-warning'}
          payload={couponWarningPayload}
          onFinish={() => applyWarnedCoupon()}
        />
      </Modal>
      <div className="checkout-title">
        <p>|</p>
        <SimpleText size="title2" color="grey">
          Checkout
        </SimpleText>
      </div>
      <Tags
        trialEnabled={trialEnabled}
        activateNow={activateNow}
        reduced={checkoutConfig?.reducedMoneyBackGuarantee}
      />
      <div className="checkout-mobile-summary-tab">
        <div
          className="checkout-mobile-summary-tab-header"
          onClick={() => {
            setMobileOrderSummaryTabOpen(!mobileOrderSummaryTabOpen)
          }}>
          <span>Order Summary</span>
          <div className="checkout-mobile-summary-tab-icon-wrapper">
            <img src={mobileOrderSummaryTabOpen ? ArrowDown : ArrowUp} />
          </div>
        </div>
        {mobileOrderSummaryTabOpen && renderSummaryCard()}
      </div>
      <SimpleBox className="checkout checkout-new" display="flex" justifyContent="center">
        <SimpleBox maxWidth={1280} mx="auto" display="flex" flexDirection="row" gap={21} width="100%" justifyContent="center">
          {!loadingCheckoutConfig && cartSteps?.length > 0 && (
            <Form name="checkout" className="checkout-first-block">
              {/* account */}
              <SimpleBox display="flex" alignItems="center" gap={12} width="100%">
                <SimpleCard color="cornflower" style={{ flex: '0 0 32px' }} rounded="circle" base>
                  <SimpleBox height={32} width={32} display="grid" alignContent="center">
                    <SimpleText size="title2" color="white">1</SimpleText>
                  </SimpleBox>
                </SimpleCard>
                {steps[cartSteps[0].stepType as string].title}
              </SimpleBox>
              {steps[cartSteps[0].stepType as string].body}
              <div className="checkout-summary-tablet">
                <SimpleBox display="flex" alignItems="center" gap={12} width="100%">
                  <SimpleCard color="cornflower" style={{ flex: '0 0 32px' }} rounded="circle" base>
                    <SimpleBox height={32} width={32} display="grid" alignContent="center">
                      <SimpleText size="title2" color="white">2</SimpleText>
                    </SimpleBox>
                  </SimpleCard>
                  <SimpleText size="title3">Selected Plan</SimpleText>
                </SimpleBox>
                {renderSummaryCard()}
              </div>
              {cartSteps.slice(1).map((step: any, index: number) => {
                const isCurrentStepPaymentMethods = step.stepType === 'payment_methods'
                return (
                  <React.Fragment key={index}>
                    <SimpleBox mt={5} display="flex" alignItems="center" gap={12} width="100%">
                      <SimpleCard color="cornflower" style={{ flex: '0 0 32px' }} base rounded="circle">
                        <SimpleBox height={32} width={32} display="grid" alignItems="center" justifyContent="center">
                          <div className="hide-on-tablet-only">
                            <SimpleText size="title2" color="white">
                              {index + 2}
                            </SimpleText>
                          </div>
                          <div className="show-on-tablet-only">
                            <SimpleText size="title2" color="white">
                              {index + 3}
                            </SimpleText>
                          </div>
                        </SimpleBox>
                      </SimpleCard>
                      {steps[step.stepType].title}
                    </SimpleBox>
                    {(isCurrentStepPaymentMethods && renderAllPaymentMethods()) || steps[step.stepType].body}
                  </React.Fragment>
                )
              })}
            </Form>
          )}
          {plan && (
            <SimpleBox className="order-summary-desktop">
              <SimpleBox display="flex" justifyContent="flex-start" alignItems="center" height={32} marginBottom={18}>
                <SimpleText size="title3">Order Summary</SimpleText>
              </SimpleBox>
              {renderSummaryCard()}
            </SimpleBox>
          )}
        </SimpleBox>
      </SimpleBox>
    </fieldset>
  )
}

CheckoutProduct.defaultProps = {
  trialEnabled: true,
  promotion: true,
}

CheckoutProduct.authorize = false
CheckoutProduct.auth = '/apps'

export default observer(CheckoutProduct)
